import React from 'react'

function IntegrationIcon({className}) {
  return (
    <svg class={`h-6 w-6 text-inherit ${className}`}  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="8" y="4" width="12" height="12" rx="2" />  <rect x="4" y="8" width="12" height="12" rx="2" /></svg>
  
  )
}

export default IntegrationIcon
