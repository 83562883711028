import React, { lazy } from 'react'
import ComponentLoader from './component-loader'
import { useAgency } from 'context/agency'
import { PREMIUM_APPS } from 'enums'

const Chat = ComponentLoader(lazy(() => import('screens/admin/Chat/')))

// TICKET
const TicketModule = ComponentLoader(lazy(() => import('screens/admin/Ticket')))
const Ticket = ComponentLoader(
  lazy(() => import('screens/admin/Ticket/ticket-list'))
)
const ViewTicket = ComponentLoader(
  lazy(() => import('screens/admin/Ticket/ticket-view'))
)

// DOCUMENT
const DocCategory = ComponentLoader(
  lazy(() => import('screens/admin/Docs/category'))
)
const DocModule = ComponentLoader(lazy(() => import('screens/admin/Docs/docs')))
const Docs = ComponentLoader(lazy(() => import('screens/admin/Docs/docs/doc')))
const CreateDoc = ComponentLoader(
  lazy(() => import('screens/admin/Docs/docs/create'))
)
const Preview = ComponentLoader(
  lazy(() => import('screens/admin/Docs/docs/preview'))
)

// CityPass
const CampaignModule = ComponentLoader(
  lazy(() => import('screens/admin/CityPass'))
)
const PassSetup = ComponentLoader(
  lazy(() => import('screens/admin/CityPass/create/create-campaign'))
)

const DealSetup = ComponentLoader(
  lazy(() => import('screens/admin/CityPass/deal-setup/deal-setup'))
)

const PremiumAppsRoutes = () => {
  const { premiumApps } = useAgency()

  const routes = []

  const isAppExist = (app) =>
    premiumApps.find((item) => item.upsale_id?.products?.includes(app))

  if (isAppExist(PREMIUM_APPS.chat))
    routes.push({
      path: 'chat',
      element: <Chat />,
    })

  if (isAppExist(PREMIUM_APPS.ticket))
    routes.push({
      path: 'ticket',
      element: <TicketModule />,
      children: [
        {
          path: '',
          index: true,
          element: <Ticket />,
        },
        {
          path: 'view',
          element: <ViewTicket />,
        },
      ],
    })

  if (isAppExist(PREMIUM_APPS.docs))
    routes.push({
      path: 'docs',
      children: [
        {
          path: '',
          index: true,
          element: <DocCategory />,
        },
        {
          path: 'view',
          element: <DocModule />,
          children: [
            {
              path: '',
              element: <Docs />,
            },
            {
              path: 'preview',
              element: <Preview />,
            },
            {
              path: 'create',
              element: <CreateDoc />,
            },
          ],
        },
      ],
    })

  if (isAppExist(PREMIUM_APPS.citypass))
    routes.push({
      path: 'city-pass',
      element: <CampaignModule />,
      children: [
        {
          path: 'pass-setup',
          index: true,
          element: <PassSetup />,
        },
        {
          path: 'deal-setup',
          element: <DealSetup />,
        },
      ],
    })

  return routes
}

export default PremiumAppsRoutes
