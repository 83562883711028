import { useAgency } from 'context/agency'
import React from 'react'

function SupportIcon() {
  const { styles } = useAgency()

  return (
    <svg
      // xmlns="http://www.w3.org/2000/svg"
      // xmlnsXlink="http://www.w3.org/1999/xlink"
      fill={styles.text_color}
      // stroke='grey'
      className='w-6 h-6'
      version='1.1'
      id='Capa_1'
      viewBox='0 0 490 490'
      // xmlSpace="preserve"
      strokeWidth='10'
    >
      <g>
        <path d='M465.45,204.248C456.781,90.169,361.179,0,244.917,0C128.656,0,33.063,90.169,24.394,204.248c-0.407,1.104-0.641,117.413-0.641,117.413c0,5.644,4.566,10.209,10.208,10.209h31.463c25.191,0,45.678-20.486,45.678-45.679v-42.936c0-25.192-20.487-45.679-45.678-45.679H45.562C57.275,97.957,142.2,20.417,244.917,20.417c102.717,0,187.651,77.54,199.364,177.161h-19.861c-25.192,0-45.688,20.486-45.688,45.679v42.936c0,25.192,20.496,45.679,45.688,45.679h19.408c-3.457,22.314-12.247,51.467-33.944,75.357c-25.205,27.745-62.73,42.822-111.637,44.896c-1.773-15.345-14.841-27.301-30.652-27.301h-37.873c-17.017,0-30.864,13.846-30.864,30.863c0,1.725-1.462,32.834,30.864,34.315h37.873c12.171,0,22.719-7.087,27.74-17.353c56.378-1.659,99.981-19.025,129.662-51.693c28.352-31.215,38.326-69.321,41.093-95.362C466.597,324.381,465.732,205.01,465.45,204.248z M65.424,217.994c13.926,0,25.262,11.334,25.262,25.262v42.936c0,13.928-11.335,25.262-25.262,25.262H44.17c0,0,0.024-92.403,0.04-93.459H65.424z M267.597,469.583h-37.873c-5.763,0-11.54-5.099-10.447-13.898c0.71-5.718,3.863-10.447,10.447-10.447h37.873c6.763,0,9.871,4.715,10.447,10.447C278.991,465.106,273.359,469.583,267.597,469.583z M424.421,311.453c-13.938,0-25.272-11.334-25.272-25.262v-42.936c0-13.928,11.334-25.262,25.272-25.262h21.213c0.017,1.056,0.04,93.459,0.04,93.459H424.421z' />
        <path d='M188.793,177.928l-38.325,38.502v18.078h86.414v-20.426h-57.31l29.647-30.189c9.042-9.277,15.244-16.871,18.62-22.781c3.376-5.902,5.06-12.111,5.06-18.62c0-11.569-3.975-20.693-11.925-27.388c-7.958-6.688-17.212-10.031-27.752-10.031c-10.549,0-19.162,2.113-25.85,6.33c-6.688,4.218-12.807,10.484-18.352,18.799l17.178,9.942c6.865-10.605,15.479-15.909,25.85-15.909c5.902,0,10.784,1.781,14.646,5.336c3.853,3.554,5.78,7.86,5.78,12.921c0,5.067-1.806,10.062-5.425,15.009C203.438,162.44,197.351,169.249,188.793,177.928z' />
        <polygon points='299.608,108.15 253.146,183.894 253.146,202.692 307.379,202.692 307.379,234.509 327.627,234.509    327.627,202.692 342.815,202.692 342.815,183.352 327.627,183.352 327.627,160.215 307.379,160.215 307.379,183.352    277.19,183.352 322.923,108.15  ' />
        <path d='M208.247,336.313c0-5.506,1.603-9.69,4.801-12.557c3.198-2.865,6.962-4.299,11.277-4.299   c9.075,0,13.617,5.465,13.617,16.41v32.594h12.5v-36.39c0-7.74-2.064-13.658-6.193-17.746c-4.128-4.097-9.545-6.145-16.239-6.145   c-4.17,0-8.055,1.044-11.666,3.124c-3.611,2.089-6.307,4.955-8.096,8.598v-34.156h-12.5v82.715h12.5V336.313z' />
        <path d='M267.857,309.07v59.391h12.499v-25.453c0-6.768,1.563-12.168,4.688-16.183c3.125-4.024,7.78-6.032,13.957-6.032   c0.218,0,0.445,0,0.672,0l-0.113-12.612c-3.87,0.072-7.538,1.303-10.994,3.683c-3.465,2.389-6.201,5.432-8.21,9.156V309.07H267.857   z' />
      </g>
    </svg>
  )
}

export default SupportIcon
