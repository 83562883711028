import React, { useMemo } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import faker from 'faker'
import configs from './config'
import WatchIcon from 'icon/watchicon'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export default function BarChart({
  color,
  title,
  description,
  date,
  chart = {},
}) {
  const { data: chartData, options } = configs(
    chart.labels || [],
    chart.datasets || {}
  )

  return (
    <div class='border-gray-400 rounded-md shadow-xl p-6'>
      <div className='h-full'>
        {useMemo(
          () => (
            <div
              variant='gradient'
              bgColor={color}
              borderRadius='lg'
              coloredShadow={color}
              py={2}
              pr={0.5}
              mt={-5}
              height='12.5rem'
              className='bg-gradient-to-r from-cyan-400 to-blue-300 shadow-sm h-52 rounded-lg -mt-12 pr-0.5 py-2'
              style={{ backgroundColor: color }}
            >
              <Bar options={options} data={chartData} />
            </div>
          ),
          [chart, color]
        )}
      </div>
      <div class='mt-2'>
        <div>
          <h1 class='font-semibold text-lg capitalize'>{title}</h1>
          <p class=''>{description}</p>
        </div>
        <div className='x-divider !my-4' />

        <div class='flex'>
          <p class=' flex'>
            <div className='me-2'>
              <WatchIcon />
            </div>
            {date}
          </p>
        </div>
      </div>
    </div>
  )
}
