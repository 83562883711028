import React from 'react'

function RebrandingIcon({className}) {
  return (
    <svg class={`h-6 w-6 text-inherit ${className}`}  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <line x1="11" y1="7" x2="17" y2="13" />  <path d="M5 19v-4l9.7 -9.7a1 1 0 0 1 1.4 0l2.6 2.6a1 1 0 0 1 0 1.4l-9.7 9.7h-4" /></svg>

  )
}

export default RebrandingIcon
