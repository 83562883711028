import React from 'react'

function BarChartIcon() {
  return (
    <div>
      <svg
        class='h-5 w-5'
        width='20'
        height='20'
        viewBox='0 0 117 117'
        version='1.1'
        fill='#000000'
        stroke='#000000'
      >
        <g id='SVGRepo_bgCarrier' stroke-width='0' />

        <g
          id='SVGRepo_tracerCarrier'
          stroke-linecap='round'
          stroke-linejoin='round'
        />

        <g id='SVGRepo_iconCarrier'>
          {' '}
          <title /> <desc /> <defs />{' '}
          <g
            fill='none'
            fill-rule='evenodd'
            id='Page-1'
            stroke='none'
            stroke-width='1'
          >
            {' '}
            <g fill-rule='nonzero' id='graph'>
              {' '}
              <path
                d='M112.4,108.4 L8.6,108.4 L8.6,4.6 C8.6,2.3 6.8,0.5 4.5,0.5 C2.2,0.5 0.4,2.3 0.4,4.6 L0.4,112.5 C0.4,114.8 2.2,116.6 4.5,116.6 L112.4,116.6 C114.7,116.6 116.5,114.8 116.5,112.5 C116.5,110.2 114.7,108.4 112.4,108.4 Z'
                fill='#2c2b2b'
                id='Shape'
              />{' '}
              <path
                d='M48.3,92.6 L48.3,33.6 C48.3,31.3 46.5,29.5 44.2,29.5 C41.9,29.5 40.1,31.3 40.1,33.6 L40.1,92.6 C40.1,94.9 41.9,96.7 44.2,96.7 C46.5,96.7 48.3,94.9 48.3,92.6 Z'
                fill='#2c2b2b'
                id='Shape'
              />{' '}
              <path
                d='M28.5,92.6 L28.5,33.6 C28.5,31.3 26.7,29.5 24.4,29.5 C22.1,29.5 20.3,31.3 20.3,33.6 L20.3,92.6 C20.3,94.9 22.1,96.7 24.4,96.7 C26.7,96.7 28.5,94.9 28.5,92.6 Z'
                fill='#2c2b2b'
                id='Shape'
              />{' '}
              <path
                d='M87.9,92.6 L87.9,33.6 C87.9,31.3 86.1,29.5 83.8,29.5 C81.5,29.5 79.7,31.3 79.7,33.6 L79.7,92.6 C79.7,94.9 81.5,96.7 83.8,96.7 C86.1,96.7 87.9,94.9 87.9,92.6 Z'
                fill='#2c2b2b'
                id='Shape'
              />{' '}
              <path
                d='M107.7,92.6 L107.7,63.1 C107.7,60.8 105.9,59 103.6,59 C101.3,59 99.5,60.8 99.5,63.1 L99.5,92.6 C99.5,94.9 101.3,96.7 103.6,96.7 C105.9,96.7 107.7,94.9 107.7,92.6 Z'
                fill='#2c2b2b'
                id='Shape'
              />{' '}
              <path
                d='M68.1,92.6 L68.1,63.1 C68.1,60.8 66.3,59 64,59 C61.7,59 59.9,60.8 59.9,63.1 L59.9,92.6 C59.9,94.9 61.7,96.7 64,96.7 C66.3,96.7 68.1,94.9 68.1,92.6 Z'
                fill='#2c2b2b'
                id='Shape'
              />{' '}
            </g>{' '}
          </g>{' '}
        </g>
      </svg>
    </div>
  )
}

export default BarChartIcon
