import { useAgency } from 'context/agency'
import ProfileButton from './profile-button'
import Select from 'component/Dropdown/dropdown'
import Api from 'utils/api'
import { useLocationInfo } from 'context/location'
import env from 'configuration'
import { getAuth } from 'utils/auth-helper'
import { useAuth } from 'context/auth'
import Side from 'screens/admin/Docs side/side'
import ButtonGradient from 'component/Buttons/dashboard-btn'

function TopHeader() {
  const { agency, locations } = useAgency()
  const { setCurrentUser, updateTour } = useAuth()
  const { setLocation } = useLocationInfo()

  const startTour = async () => {
    const screen = window.location.pathname.replace('/', '')
    console.log(screen,'screen')
    updateTour({[screen]: false})

    // const { response } = await Api('/user' , 'put', {
    //   payload:{ tour: {...setCurrentUser.tour, [screen]: false}, }
    // })
    // if(response) {
    //   setCurrentUser(response.data)
    // }
  }

  const handleSelectLocation = async (e) => {
    const { value } = e.target
    const location = await locations.find((loc) => loc._id === value)
    const { response } = await Api('/user', 'put', {
      payload: { selected_location: location.api_key },
      notify: true,
    })
    if (response) {
      setCurrentUser(response.data)
      setLocation(location)
      window.open(env.LOCATION_LEVEL + '?auth=' + getAuth().token, '_blank')
    }
  }

  return (
    <div className="flex items-center">
      <div className="flex items-center ">
        <div className='mr-2'>
          <ButtonGradient 
          label={'Start tour'}
          onClick={startTour}
          />
        </div>
        <div class="mr-4">
          <Select
            onSelect={handleSelectLocation}
            placeholder={'Select Location'}
            value={agency.selected_location}
            options={locations.map((location) => ({
              label: location.name,
              value: location._id,
            }))}
          />
        </div>
        <div class="mr-2">
          <Side />
        </div>
        <div>
          <ProfileButton />
        </div>
      </div>
    </div>
  )
}

export default TopHeader
