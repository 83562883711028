const env = {
  SERVER: 'https://api.walletcampaigns.com',
  API_URL: 'https://api.walletcampaigns.com/api',
  STRIPE: {
    PUBLIC_KEY:
      'pk_live_51M7zTzGZOzsN0UuR5NFf5og4q7ZhiahifU305gDqnqIXLISh6Oek62dYbUnXRPzQkt9BpM3QavyrsjM2KXLAt2x400xcEDSPRS', // walletcampaigns
    CLIENT_ID: 'ca_Mrj5xmu7CRAeF3mF55TV6vKvgSCJCUQJ', // walletcampaigns
  },
  LOCATION_LEVEL: 'https://app.walletcampaigns.com',
  HOSTING_IP: '167.172.56.80',
  SUPER_ADMIN: '64942c8144741664d072df0a',
  GHL: {
    V2_BASE_URL: 'https://api.msgsndr.com',
    // REDIRECT: {
    //   ASSOCIATE: 'http://localhost:3010/integrations/ghl/associate',
    //   AGENCY_APP: 'http://localhost:3000/integrations/crm/associate',
    // },
    AGENCY: {
      // WalletCampaignsAgencyConnection
      CLIENT_ID: '66069c1c7cdc95f01fba3388-lucjoato',
      CLIENT_SECRET: 'ca57e3e3-af71-499e-afe0-f001543cb6b8',
      REDIRECT: 'https://admin.walletcampaigns.com/integrations/crm/associate',
      SCOPE:
        'businesses.readonly businesses.write companies.readonly calendars.readonly calendars.write calendars/events.readonly calendars/events.write calendars/groups.readonly calendars/groups.write calendars/resources.readonly calendars/resources.write campaigns.readonly conversations.readonly conversations.write conversations/message.readonly conversations/message.write contacts.readonly contacts.write forms.readonly forms.write invoices.readonly invoices.write invoices/schedule.readonly invoices/schedule.write invoices/template.readonly invoices/template.write links.readonly links.write locations.write locations.readonly locations/customValues.readonly locations/customValues.write locations/customFields.readonly locations/customFields.write locations/tasks.readonly locations/tasks.write locations/tags.readonly locations/tags.write locations/templates.readonly medias.readonly medias.write funnels/redirect.readonly funnels/redirect.write opportunities.readonly opportunities.write payments/orders.readonly payments/transactions.readonly payments/subscriptions.readonly products.readonly products.write products/prices.readonly products/prices.write saas/company.read saas/company.write saas/location.read saas/location.write surveys.readonly users.readonly users.write workflows.readonly oauth.write snapshots.readonly oauth.readonly snapshots.write',
    },
    LOCATION: {
      // Wallet Campaigns Agency Location
      CLIENT_ID: '64f200154074ebeac33e3c6d-lm676w6g',
      CLIENT_SECRET: 'afabcf54-f83b-4294-949f-bd0dc2578107',
      REDIRECT: 'https://admin.walletcampaigns.com/integrations/crm/associate',
      SCOPE:
        'businesses.readonly businesses.write calendars.readonly calendars.write calendars/events.readonly calendars/events.write campaigns.readonly conversations.readonly conversations.write conversations/message.readonly conversations/message.write contacts.readonly contacts.write forms.readonly forms.write links.readonly links.write locations.readonly locations/customValues.readonly locations/customValues.write locations/customFields.readonly locations/customFields.write locations/tasks.readonly locations/tasks.write locations/tags.readonly locations/tags.write medias.readonly locations/templates.readonly medias.write opportunities.readonly opportunities.write surveys.readonly users.readonly users.write workflows.readonly',
    },
  },
}

export default env
