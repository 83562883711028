import React from 'react'

function AccountIcon({className}) {
  return (
    <svg class={`h-6 w-6 text-inherit ${className}`}  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />  <circle cx="12" cy="7" r="4" /></svg>

  )
}

export default AccountIcon
