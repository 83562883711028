import { useAuth } from 'context/auth'
import React, { useEffect } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import Api from 'utils/api'

function SinglePageCustomMenuLinkLayout() {
  const { location_id, email } = useParams()
  const { auth, saveAuth } = useAuth()

  const validateUser = async () => {
    const { response } = await Api('/auth/cm_single_page/user', 'post', {
      payload: {
        location_id,
        email,
      },
    })
    saveAuth(response?.data)
  }

  const onLoad = () => {
    if (!auth) validateUser()
  }

  useEffect(onLoad, [])

  return (
    <div className='p-12'>
      <Outlet />
    </div>
  )
}

export default SinglePageCustomMenuLinkLayout
