import Loader from 'component/loader'
import Modal from 'component/modal'
import ClickOutSide from 'hooks/click-outside'
import CloseIcon from 'icon/close'
import Fullscreenicon from 'icon/fullscreenicon'
import QuestionIcon from 'icon/questionicon'
import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import { useDocs } from './docs-context'

const Side = () => {
  const navigate = useNavigate()
  const { docs, openModal, selectedDoc, setOpenModal, setSelectedDoc } =
    useDocs()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen)
  }
  const closeModal = () => {
    setOpenModal(false)
  }

  const handleSelectedDoc = (doc) => {
    setSelectedDoc(doc)
    setOpenModal(true)
  }

  return (
    <div>
      {/* // <!-- drawer init and show --> */}
      <div class='text-center'>
        <button
          onClick={toggleDrawer}
          class='border rounded-md px-4 py-1 uppercase'
        >
          {/* <QuestionIcon />  */}
          Help
        </button>
      </div>

      {/* // <!-- drawer component --> */}
      <ClickOutSide onClickOutside={() => setIsDrawerOpen(false)}>
        <div
          id='drawer-navigation'
          className={`fixed top-0 right-0 z-40 md:w-96 lg:w-96 w-full h-screen overflow-y-auto transition-transform ${
            isDrawerOpen ? 'translate-x-0' : 'translate-x-full'
          } bg-white `}
        >
          <button
            onClick={() => setIsDrawerOpen(false)}
            type='button'
            class='text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 end-2.5 inline-flex items-center'
          >
            <CloseIcon />
            <span class='sr-only'>Close menu</span>
          </button>
          <div>
            <div class='mb-8 p-4'>
              <h1 class='font-bold text-2xl '>Documentation</h1>
              <p className='text-gray-500 font-extralight'>
                Click to view details about the topic.
              </p>
            </div>
            {docs
              .filter((category) => category.docs?.length)
              .map((category) => (
                <div>
                  <h1 class='font-bold text- text-lg p-4'>{category.name}</h1>
                  <hr />
                  {category.docs.map((doc) => (
                    <div
                      className='hover:bg-gray-100 p-4 cursor-pointer'
                      onClick={() => handleSelectedDoc(doc)}
                    >
                      <h2 class='font-semibold'>{doc.title}</h2>
                      <p
                        className='text-gray-500 text-sm font-extralight w-full max-h-[60px] line-clamp-3'
                        dangerouslySetInnerHTML={{ __html: doc.doc }}
                      ></p>
                    </div>
                  ))}
                </div>
              ))}
          </div>
        </div>
        <Modal open={openModal} onClose={closeModal}>
          <div className='w-[80vw] h-[80vh] overflow-scroll'>
            <div
              onClick={() => {
                closeModal()
                navigate('./docs/preview', { state: selectedDoc })
              }}
              class='flex justify-end pt-2  mr-3 '
            >
              <Fullscreenicon />
            </div>
            <div className='flex items-center justify-between p-4 md:p-5 border-b rounded-t'>
              <img
                width='100%'
                style={{ height: '250px', objectFit: 'cover' }}
                src={selectedDoc?.cover_img || 'https://placehold.co/600x600'}
                alt='this is a setting'
              ></img>
            </div>
            <div class='p-5'>
              <h3 className='font-bold mb-4'>{selectedDoc?.title}</h3>
              <p dangerouslySetInnerHTML={{ __html: selectedDoc?.doc }}></p>
            </div>
          </div>
        </Modal>
      </ClickOutSide>
    </div>
  )
}

export default Side
