import Button from 'component/Buttons/login-button'
import { useAgency } from 'context/agency'
import { Link } from 'enums'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Api from 'utils/api'

function ShareLink() {
  const navigate = useNavigate()
  const { code } = useParams()
  const { agency } = useAgency()
  console.log(agency, 'agecy')
  const [getSnapshotCode, setGetSnapshotCode] = useState()
  const [loading, setLoading] = useState(true)
  const [linkExpired, setLinkExpired] = useState(false)
  const getSnapshotByLinkCode = async () => {
    const { response } = await Api(
      '/wc_snapshot/getsnapshotcode?code=' + code,
      'get'
    )
    if (response) {
      setGetSnapshotCode(response.data)
      setLoading(false)
      console.log(response, 'getcode')
    } else {
      setLinkExpired(true)
      setLoading(false)
    }
  }

  useEffect(() => {
    getSnapshotByLinkCode()
  }, [])

  const handleSubmit = async () => {
    const payload = {
      snapshot_id: getSnapshotCode._id,
      agency_id: agency._id,
      code: code,
      snapshot_name: getSnapshotCode.snapshot_name,
    }
    const { response } = await Api('/wc_snapshot/importsnapshot', 'post', {
      payload,
      notify: true,
    })
    if (response) {
      navigate('../wc-snapshot/1')
    }
  }

  if (linkExpired) {
    return <div className='text-center font-bold'>This Link is expired.</div>
  }

  return (
    <div class='text-3xl font-thin text-center mt-10'>
      <div> Do you want to import this snapshot to this account? </div>
      <div className='mt-5'>
        <Button onClick={handleSubmit} label={'Yes! Import now.'} />
      </div>
    </div>
  )
}

export default ShareLink
