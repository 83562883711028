import React from 'react'
import Loader from '../loader'

function Button({
  type,
  disabled,
  label,
  processing,
  onClick,
  children,
  className,
}) {
  return (
    <button
      onClick={onClick}
      type={type || 'button'}
      className={
        className ||
        'text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center'
      }
      disabled={disabled || processing}
    >
      {processing ? <Loader /> : label || children}
    </button>
  )
}

export default Button
