import * as USER from './user'
import * as TICKET from './ticket'
import * as CAMPAIGN from './campaigns'
import * as GROWTHTOOL from './growth-tool'

const Environment = {
  DEVELOPMENT: 'development',
  STAGING: 'staging',
  PRODUCTION: 'production',
}

const ReqMethods = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  DELETE: 'delete',
}

const BlackListDomain = [
  'superadmin.walletcampaigns.com',
  'dev.superadmin.walletcampaigns.com',
  'admin.walletcampaigns.com',
  'dev.admin.walletcampaigns.com',
  'app.walletcampaigns.com',
  'dev.app.walletcampaigns.com',
  'wallet.walletcampaigns.com',
  'dev.walletapp.walletcampaigns.com',
  'scan.walletcampaigns.com',
  'dev.scan.walletcampaigns.com/',
]
const PREMIUM_APPS = {
  chat: 'chat',
  ticket: 'ticket',
  docs: 'docs',
  citypass: 'citypass',
}

const DOCUMENT_FOR = {
  AGENCY: 'agency',
  LOCATION: 'location',
}

const SnapshotSharedLink = {
  ONETIME: 'one-time share link',
  PERMANENT: 'permanent link',
}

export {
  USER,
  BlackListDomain,
  CAMPAIGN,
  GROWTHTOOL,
  Environment,
  ReqMethods,
  PREMIUM_APPS,
  SnapshotSharedLink,
  DOCUMENT_FOR,
}
