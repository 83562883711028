const env = {
  SERVER: 'https://dev.api.walletcampaigns.com',
  API_URL: 'https://dev.api.walletcampaigns.com/api',
  STRIPE: {
    PUBLIC_KEY:
      'pk_test_51M7zTzGZOzsN0UuRLyM3qnOwVAOLRNjLaURRpYfwFSyUsi6fKB7VNQTwCzsNBwwzorRBXRPXA5xRqRgg9Txt2NAf000HSNLkUC', // walletcampaigns
    CLIENT_ID: 'ca_Mrj5a8X6y0mveoxrFTkewDhfo7PYkhbJ', // walletcampaigns
  },
  LOCATION_LEVEL: 'https://dev.app.walletcampaigns.com',
  HOSTING_IP: '138.68.70.223',
  SUPER_ADMIN: '656607903041e1ca410cfc49',
  GHL: {
    V2_BASE_URL: 'https://api.msgsndr.com',
    // REDIRECT: {
    //   ASSOCIATE: 'http://localhost:3010/integrations/ghl/associate',
    //   AGENCY_APP: 'http://localhost:3000/integrations/crm/associate',
    // },
    AGENCY: {
      // dev-A-Agency
      CLIENT_ID: '6650aebbea24ff15eddc2c35-lwktqop1',
      CLIENT_SECRET: 'd6e70ef0-8ade-4b8a-a2c9-055cb4155a93',
      REDIRECT:
        'https://dev.admin.walletcampaigns.com/integrations/crm/associate',
      SCOPE:
        'businesses.readonly businesses.write companies.readonly calendars.readonly calendars.write calendars/events.readonly calendars/events.write calendars/groups.readonly calendars/groups.write calendars/resources.readonly calendars/resources.write campaigns.readonly conversations.readonly conversations.write conversations/message.readonly conversations/message.write contacts.readonly contacts.write forms.readonly forms.write invoices.readonly invoices.write invoices/schedule.readonly invoices/schedule.write invoices/template.readonly invoices/template.write links.readonly links.write locations.write locations.readonly locations/customValues.readonly locations/customValues.write locations/customFields.readonly locations/customFields.write locations/tasks.readonly locations/tasks.write locations/tags.readonly locations/tags.write locations/templates.readonly medias.readonly medias.write funnels/redirect.readonly funnels/redirect.write opportunities.readonly opportunities.write payments/orders.readonly payments/transactions.readonly payments/subscriptions.readonly products.readonly products.write products/prices.readonly products/prices.write saas/company.read saas/company.write saas/location.read saas/location.write surveys.readonly users.readonly users.write workflows.readonly oauth.write snapshots.readonly oauth.readonly snapshots.write',
    },
    LOCATION: {
      // dev-A-Location
      CLIENT_ID: '6650b3de856b16bfaf1ba7e3-lwkunij3',
      CLIENT_SECRET: 'b56b1e68-78f3-4b0b-a0f4-e60cd87a3251',
      REDIRECT:
        'https://dev.admin.walletcampaigns.com/integrations/crm/associate',
      SCOPE:
        'businesses.readonly businesses.write calendars.readonly calendars.write calendars/events.readonly calendars/events.write campaigns.readonly conversations.readonly conversations.write conversations/message.readonly conversations/message.write contacts.readonly contacts.write forms.readonly forms.write links.readonly links.write locations.readonly locations/customValues.readonly locations/customValues.write locations/customFields.readonly locations/customFields.write locations/tasks.readonly locations/tasks.write locations/tags.readonly locations/tags.write medias.readonly locations/templates.readonly medias.write opportunities.readonly opportunities.write surveys.readonly users.readonly users.write workflows.readonly',
    },
  },
}

export default env
