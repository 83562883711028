import React from 'react'

function BillIcon({className}) {
  return (
    <svg class={`h-6 w-6 text-inherit ${className}`}  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <rect x="1" y="4" width="22" height="16" rx="2" ry="2" />  <line x1="1" y1="10" x2="23" y2="10" /></svg>

  )
}

export default BillIcon
