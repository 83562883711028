import React, { useEffect, useState } from 'react'
import TopHeader from './top-header'
import NavItem from './nav-item'
import NavDropdown from './nav-dropdown'
import AppLogo from './app-logo'
import ChartIcon from 'icon/chart'
import HomeIcon from 'icon/home'
import NewsFeedIcon from 'icon/news'
import SpeakerIcon from 'icon/speaker'
import ContactIcon from 'icon/contact'
import SettingIcon from 'icon/setting'
import Offericon from 'icon/offerIcon'
import LocationIcon from 'icon/locationicon'
import WebhookIcon from 'icon/webhookicon'
import WalletIcon from 'icon/walleticon'
import Docs from 'icon/docs'
import ScanIcon from 'icon/scanIcon'
import MarketIcon from 'icon/marketIcon'
import BusinessIcon from 'icon/businessicon'
import BrandIcon from 'icon/brandicon'
import AccountIcon from 'icon/accounticon'
import PasssetupIcon from 'icon/passsetupicon'
import InfoIcon from 'icon/infoicon'
import TeamIcon from 'icon/teamicon'
import LinksIcon from 'icon/linksicon'
import RebrandingIcon from 'icon/rebrangingicon'
import IntegrationIcon from 'icon/integrationicon'
import BillIcon from 'icon/billicon'
import TicketIcon from 'icon/ticketIcon'
import { useAgency } from 'context/agency'
import { PREMIUM_APPS } from 'enums'
import ChatIcon from 'icon/chatIcon'
import GraphIcon from 'icon/graph'
import DocIcon from 'icon/docIcon'
import KeyIcon2 from 'icon/key'
import SnapshotIcon from 'icon/snapshot'
import SupportIcon from 'icon/supporticon'
import UserIcon from 'icon/usersIcon'
import ButtonGradient from 'component/Buttons/dashboard-btn'
import SideNAvButtonGradient from 'component/Buttons/sidenav-btn'
import BarChart from 'component/chart/bar/bar-chart'
import BarChartIcon from 'icon/barchart'
import Detail from 'icon/detail'

const premiumAppsNavItem = {
  [PREMIUM_APPS.chat]: {
    label: 'Chat',
    path: '/chat',
    icon: <ChatIcon />,
  },
  [PREMIUM_APPS.ticket]: {
    label: 'Ticket',
    path: '/ticket',
    icon: <TicketIcon />,
  },
  [PREMIUM_APPS.docs]: {
    label: 'Docs',
    path: '/docs',
    icon: <DocIcon />,
  },
  [PREMIUM_APPS.citypass]: {
    label: 'City Pass',
    icon: <SpeakerIcon />,
    children: [
      {
        label: 'Pass Setup',
        path: '/city-pass/pass-setup',
        icon: <PasssetupIcon />,
      },
      {
        label: 'Default Deal Setup',
        path: '/city-pass/deal-setup',
        icon: <SettingIcon />,
      },
      {
        label: 'Contact',
        path: '/cityPass-contact',
        icon: <ContactIcon />,
      },
    ],
  },
}

const menu = [
  {
    label: 'Home',
    path: '/home',
    icon: <HomeIcon />,
  },
  {
    label: 'Dashboard',
    path: '/dashboard',
    icon: <GraphIcon />,
  },
  {
    label: 'News Feed',
    path: '/news',
    icon: <NewsFeedIcon />,
  },
  {
    label: 'Accounts',
    icon: <AccountIcon />,
    children: [
      {
        label: 'Business',
        path: '/business',
        icon: <BusinessIcon />,
      },
      {
        label: 'Brand',
        path: '/brand',
        icon: <BrandIcon />,
      },
      {
        label: 'Location',
        path: '/location',
        icon: <LocationIcon />,
      },
      {
        label: 'Users',
        path: '/locationsusers',
        icon: <UserIcon />,
      },
    ],
  },
  // {
  //   label: 'Offers',
  //   path: '/offers',
  //   icon: <Offericon />,
  // },
  {
    label: 'Configurator',
    icon: <SettingIcon />,
    children: [
      {
        label: 'Location App',
        path: '/locationConfig',
        icon: <LocationIcon />,
      },
      {
        label: 'Scan App',
        path: '/scannerConfig',
        icon: <ScanIcon />,
      },
      {
        label: 'Growthtool',
        path: '/growthtool',
        icon: <ChartIcon />,
      },
      // {
      //   label: 'Api Docs',
      //   path: '/apidocs',
      //   icon: <Docs />,
      // },
    ],
  },
  {
    label: 'Wallet App',
    icon: <WalletIcon />,
    children: [
      {
        label: 'Branding',
        path: '/wallet-branding',
        icon: <RebrandingIcon />,
      },
      {
        label: 'Contact',
        path: '/wallet-contact',
        icon: <ContactIcon />,
      },
    ],
  },
  {
    label: 'Webhook',
    path: '/webhook',
    icon: <WebhookIcon />,
  },
  {
    label: 'Marketplace',
    path: '/market',
    icon: <MarketIcon />,
  },
  {
    label: 'Support',
    path: '/support',
    icon: <SupportIcon />,
  },
  {
    label: 'Snapshot',
    path: '/wc-snapshot',
    icon: <SnapshotIcon />,
  },
  {
    label: 'SaaS Configurator',
    path: '/sassconfigurator',
    icon: <BarChartIcon />,
  },
  {
    label: 'CRM Details',
    path: '/crmdetails',
    icon: <Detail />,
  },
]

const settings = [
  // {
  //   label: 'Client Access',
  //   path: '/settings/client',
  //   icon: <AccountIcon />,
  // },
  {
    label: 'Basic Info',
    path: '/settings/info',
    icon: <InfoIcon />,
  },
  {
    label: 'Team',
    path: '/settings/team',
    icon: <TeamIcon />,
  },
  {
    label: 'ApiKey',
    path: '/settings/apikey',
    icon: <KeyIcon2 />,
  },
  {
    label: 'Links',
    path: '/settings/links',
    icon: <LinksIcon />,
  },
  {
    label: 'Rebranding',
    path: '/settings/rebranding',
    icon: <RebrandingIcon />,
  },
  {
    label: 'Integration',
    path: '/settings/integration',
    icon: <IntegrationIcon />,
  },
  {
    label: 'Billing',
    path: '/settings/billing',
    icon: <BillIcon />,
  },
  {
    label: 'Snapshot',
    path: '/settings/snapshot',
    icon: <SnapshotIcon />,
  },
]

function Sidebar() {
  const { premiumApps, styles } = useAgency()
  const [openSideMenu, setOpenSideMenu] = useState(false)
  const [showSettings, setShowSettings] = useState(
    window.location.pathname.includes('settings')
  )
  const sidenav_bg_color = styles.sidenav_bg_color
  const page_color = styles.page_bg_color
  const s_text_color = styles.sidenav_text_color

  const isAppExist = (app) => {
    console.log(premiumApps, app, 'sorry')

    return premiumApps.find((item) => item.upsale_id?.products?.includes(app))
  }

  const getPremiumApps = () => {
    const apps = []
    if (isAppExist(PREMIUM_APPS.citypass))
      apps.push(premiumAppsNavItem[PREMIUM_APPS.citypass])

    if (isAppExist(PREMIUM_APPS.chat))
      apps.push(premiumAppsNavItem[PREMIUM_APPS.chat])

    if (isAppExist(PREMIUM_APPS.ticket))
      apps.push(premiumAppsNavItem[PREMIUM_APPS.ticket])

    if (isAppExist(PREMIUM_APPS.docs))
      apps.push(premiumAppsNavItem[PREMIUM_APPS.docs])

    return apps
  }

  const renderItem = (menu_item) => (
    <div key={menu_item.label}>
      {menu_item.children ? (
        <NavDropdown {...menu_item} />
      ) : (
        <NavItem
          {...menu_item}
          active={window.location.pathname == menu_item.path}
        />
      )}
    </div>
  )

  return (
    <div>
      <nav
        style={{ backgroundColor: page_color }}
        className='fixed top-0 z-50 w-full bg-white border-b border-gray-200 '
      >
        <div className='px-3 py-3 lg:px-5 lg:pl-3'>
          <div className='flex items-center justify-between'>
            <AppLogo onToggle={() => setOpenSideMenu(!openSideMenu)} />
            <TopHeader />
          </div>
        </div>
      </nav>
      <aside
        style={{
          backgroundColor: sidenav_bg_color,
        }}
        className={`fixed top-0 left-0 z-40 w-64 h-screen pt-20 transition-transform bg-white border-r border-gray-200 sm:translate-x-0  ${
          openSideMenu ? 'transform-none' : '-translate-x-full'
        }`}
      >
        <div
          style={{
            backgroundColor: sidenav_bg_color,
          }}
          className='px-3 pb-4 overflow-y-auto h-[calc(100vh-140px)] bg-white '
        >
          {showSettings && (
            <div>
              <SideNAvButtonGradient
                type='button'
                onClick={() => {
                  setShowSettings(false)
                }}
                className={'w-full pb-2 mb-4'}
              >
                Go Back
              </SideNAvButtonGradient>
            </div>
          )}
          <ul className='space-y-2 font-medium'>
            {(showSettings ? settings : menu).map((menu_item) =>
              renderItem(menu_item)
            )}
            {!showSettings && getPremiumApps().length ? (
              <>
                <hr />
                <div className='text-xs' style={{ color: s_text_color }}>
                  Premium
                </div>
              </>
            ) : (
              ''
            )}
            {(showSettings ? [] : getPremiumApps()).map((menu_item) =>
              renderItem(menu_item)
            )}
          </ul>
          {!showSettings && (
            <div class='absolute bottom-0 w-full px-4 -ml-3 mb-3'>
              <SideNAvButtonGradient
                type='button'
                onClick={() => {
                  setShowSettings(true)
                }}
                className={'w-full pb-2'}
              >
                Settings
              </SideNAvButtonGradient>
            </div>
          )}
        </div>
      </aside>
    </div>
  )
}

export default Sidebar
