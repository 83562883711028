import React, { lazy } from 'react'
import ComponentLoader from './component-loader'
import SinglePageCustomMenuLinkLayout from 'screens/public/SinglePageCMLayout'
// import Newsfeeds from 'screens/admin/Newsfeed/newsfeed'

// NEWSFEED
const NewsFeeds = ComponentLoader(
  lazy(() => import('screens/public/Newsfeed/index'))
)

const PublicRoutes = [
  {
    path: '/',
    element: <SinglePageCustomMenuLinkLayout />,
    children: [
      {
        path: 'feed/:location_id/:email',
        element: <NewsFeeds />,
      },
    ],
  },
  {
    path: 'location/:location_id/user/:email',
    element: (
      <div className='p-12'>
        <NewsFeeds />,
      </div>
    ),
  },
  
]

export default PublicRoutes
