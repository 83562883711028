import React, { lazy } from 'react'
import ComponentLoader from './component-loader'
import AdminLayout from 'screens/admin/AdminLayout'
import { Navigate } from 'react-router-dom'

// PROFILE
const Profile = ComponentLoader(
  lazy(() => import('screens/admin/Profile/profile'))
)

const AgencyNotFound = ComponentLoader(
  lazy(() => import('screens/unauthorize/agency-not-found'))
)

const NoAgencyRoutes = [
  {
    path: '',
    element: <AdminLayout />,
    children: [
      {
        path: '',
        index: true,
        element: <AgencyNotFound />,
      },
      {
        path: 'profile',
        element: <Profile />,
      },
    ],
  },
  {
    path: '',
    element: <Navigate replace to={'/'} />,
  },
  {
    path: '*',
    element: <Navigate replace to={'/'} />,
  },
]

export default NoAgencyRoutes
