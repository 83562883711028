import React from 'react'

function Detail({className}) {
  return (
    // <svg
    //   class={`h-6 w-6 text-inherit ${className}`}
    //   width="24"
    //   height="24"
    //   viewBox="0 0 24 24"
    //   stroke-width="2"
    //   stroke="currentColor"
    //   fill="none"
    //   stroke-linecap="round"
    //   stroke-linejoin="round"
    // >
    //   {' '}
    //   <path stroke="none" d="M0 0h24v24H0z" />{' '}
    //   <polyline points="5 12 3 12 12 3 21 12 19 12" />{' '}
    //   <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />{' '}
    //   <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" />
    // </svg>
    <svg 
    fill="#000000"
    class='h-5 w-5'
        width='20'
        height='20'
        viewBox="0 0 34 34" 
    version="1.1" 
    preserveAspectRatio="xMidYMid meet" 
    xmlns="http://www.w3.org/2000/svg" 
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
      <g id="SVGRepo_iconCarrier"> <title>details-line</title> 
      <path d="M32,6H4A2,2,0,0,0,2,8V28a2,2,0,0,0,2,2H32a2,2,0,0,0,2-2V8A2,2,0,0,0,32,6Zm0,22H4V8H32Z" 
      class="clr-i-outline clr-i-outline-path-1"></path>
      <path d="M9,14H27a1,1,0,0,0,0-2H9a1,1,0,0,0,0,2Z" class="clr-i-outline clr-i-outline-path-2"></path>
      <path d="M9,18H27a1,1,0,0,0,0-2H9a1,1,0,0,0,0,2Z" class="clr-i-outline clr-i-outline-path-3"></path>
      <path d="M9,22H19a1,1,0,0,0,0-2H9a1,1,0,0,0,0,2Z" class="clr-i-outline clr-i-outline-path-4"></path> 
      <rect x="0" y="0" width="36" height="36" fill-opacity="0"></rect> </g>
      </svg>
  )
}

export default Detail
