import { Environment } from 'enums'
import LocalEnv from './env.local'
import DevEnv from './env.dev'
import ProdEnv from './env.prod'

let env = LocalEnv

// const env = {
//   SERVER: 'http://localhost:8082',
//   API_URL: 'http://localhost:8082/api',
//   STRIPE: {
//     PUBLIC_KEY:
//       'pk_test_51M7zTzGZOzsN0UuRLyM3qnOwVAOLRNjLaURRpYfwFSyUsi6fKB7VNQTwCzsNBwwzorRBXRPXA5xRqRgg9Txt2NAf000HSNLkUC', // walletcampaigns
//     CLIENT_ID: 'ca_Mrj5a8X6y0mveoxrFTkewDhfo7PYkhbJ', // walletcampaigns
//   },
//   LOCATION_LEVEL: 'http://localhost:3030',
//   HOSTING_IP: '138.68.70.223',
//   SUPER_ADMIN: '656607903041e1ca410cfc49',
//   GHL: {
//     V2_BASE_URL: 'https://api.msgsndr.com',
//     REDIRECT: {
//       ASSOCIATE: 'http://localhost:3010/integrations/ghl/associate',
//       AGENCY_APP: 'http://localhost:3000/integrations/crm/associate',
//     },
//     AGENCY: {
//       // WalletCampaignsAgencyConnection
//       CLIENT_ID: '66069c1c7cdc95f01fba3388-lucjoato',
//       CLIENT_SECRET: 'ca57e3e3-af71-499e-afe0-f001543cb6b8',
//       SCOPE:
//         'businesses.readonly businesses.write companies.readonly calendars.readonly calendars.write calendars/events.readonly calendars/events.write calendars/groups.readonly calendars/groups.write calendars/resources.readonly calendars/resources.write campaigns.readonly conversations.readonly conversations.write conversations/message.readonly conversations/message.write contacts.readonly contacts.write forms.readonly forms.write invoices.readonly invoices.write invoices/schedule.readonly invoices/schedule.write invoices/template.readonly invoices/template.write links.readonly links.write locations.write locations.readonly locations/customValues.readonly locations/customValues.write locations/customFields.readonly locations/customFields.write locations/tasks.readonly locations/tasks.write locations/tags.readonly locations/tags.write locations/templates.readonly medias.readonly medias.write funnels/redirect.readonly funnels/redirect.write opportunities.readonly opportunities.write payments/orders.readonly payments/transactions.readonly payments/subscriptions.readonly products.readonly products.write products/prices.readonly products/prices.write saas/company.read saas/company.write saas/location.read saas/location.write surveys.readonly users.readonly users.write workflows.readonly oauth.write snapshots.readonly oauth.readonly snapshots.write',
//     },
//     LOCATION: {
//       // Wallet Campaigns Agency Location
//       CLIENT_ID: '64f200154074ebeac33e3c6d-lm676w6g',
//       CLIENT_SECRET: 'afabcf54-f83b-4294-949f-bd0dc2578107',
//       SCOPE:
//         'businesses.readonly businesses.write calendars.readonly calendars.write calendars/events.readonly calendars/events.write campaigns.readonly conversations.readonly conversations.write conversations/message.readonly conversations/message.write contacts.readonly contacts.write forms.readonly forms.write links.readonly links.write locations.readonly locations/customValues.readonly locations/customValues.write locations/customFields.readonly locations/customFields.write locations/tasks.readonly locations/tasks.write locations/tags.readonly locations/tags.write medias.readonly locations/templates.readonly medias.write opportunities.readonly opportunities.write surveys.readonly users.readonly users.write workflows.readonly',
//     },
//   },
// }

if (process.env.REACT_APP_ENV === Environment.STAGING) {
  env = Object.assign(env, DevEnv)

  //   env.API_URL = 'https://dev.api.walletcampaigns.com/api'
  //   env.SERVER = 'https://dev.api.walletcampaigns.com'
  //   env.GHL.REDIRECT.ASSOCIATE =
  //     'https://dev.admin.walletcampaigns.com/integrations/ghl/associate'
  //   env.GHL.REDIRECT.AGENCY_APP =
  //     'http://dev.admin.walletcampaign.com/integrations/ghl/associate'
  //   env.LOCATION_LEVEL = 'https://dev.app.walletcampaigns.com'
  //   env.HOSTING_IP = '138.68.70.223'
}

if (process.env.REACT_APP_ENV === Environment.PRODUCTION) {
  env = Object.assign(env, ProdEnv)

  //   env.API_URL = 'https://api.walletcampaigns.com/api'
  //   env.SERVER = 'https://api.walletcampaigns.com'
  //   env.GHL.REDIRECT.ASSOCIATE =
  //     'https://admin.walletcampaigns.com/integrations/ghl/associate'
  //   env.GHL.REDIRECT.AGENCY_APP =
  //     'https://admin.walletcampaigns.com/integrations/ghl/associate'
  //   env.LOCATION_LEVEL = 'https://app.walletcampaigns.com'
  //   env.HOSTING_IP = '167.172.56.80'
  //   env.STRIPE.PUBLIC_KEY =
  //     'pk_live_51M7zTzGZOzsN0UuR5NFf5og4q7ZhiahifU305gDqnqIXLISh6Oek62dYbUnXRPzQkt9BpM3QavyrsjM2KXLAt2x400xcEDSPRS' // walletcampaigns
  //   env.STRIPE.CLIENT_ID = 'ca_Mrj5xmu7CRAeF3mF55TV6vKvgSCJCUQJ' // walletcampaigns
  //   env.SUPER_ADMIN = '64942c8144741664d072df0a'
}

export default env
