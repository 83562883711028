import Sidebar from 'component/Sidebar/sidebar'
import { ChatConnectionProvider } from 'context/chat-connection'
import React from 'react'
import { Outlet } from 'react-router-dom'
import { DocProvider } from './Docs side/docs-context'
import { useAgency } from 'context/agency'

function AdminLayout() {
  const { styles } = useAgency()

  const page_bg_color = styles.page_bg_color

  return (
    <ChatConnectionProvider>
      <DocProvider>
        <Sidebar />
        <main>
          <div
            style={{
              backgroundColor: page_bg_color,
            }}
            className="p-8 sm:ml-64 mt-14"
          >
            <Outlet />
          </div>
        </main>
      </DocProvider>
    </ChatConnectionProvider>
  )
}

export default AdminLayout
