import React from 'react'
import { useAuth } from 'context/auth'
import AuthRoutes from './auth-routes'
import AdminRoutes from './admin-routes'
import InvalidAccessRoutes from './unauthorize-routes'
import { TYPES } from 'enums/user'
import { useAgency } from 'context/agency'
import NoAgencyRoutes from './noagency-routes'
import ChangePassRequiredRoutes from './change-pass-req-routes'
import { isGHLPlatform } from 'utils/auth-helper'
import SubscriptionExpiredRoutes from './subscription-expired-routes'
import AppRoutes from './app-routes'

const Routes = () => {
  const { currentUser } = useAuth()
  const { agency } = useAgency()
  const currentRoutes = AppRoutes

  const routes = []

  // PUBLIC
  if (!currentUser) {
    currentRoutes[0].children = routes.concat(AuthRoutes)
    return currentRoutes
  }

  // PREMISSION DENIED
  if (currentUser && currentUser.user_type !== TYPES.AGENCY) {
    currentRoutes[0].children = routes.concat(InvalidAccessRoutes)
    return currentRoutes
  }

  // NO AGENCY FOUND
  if (currentUser && !agency) {
    currentRoutes[0].children = routes.concat(NoAgencyRoutes)
    return currentRoutes
  }

  // CHANGE PASSWORD REQUIRED
  if (currentUser && currentUser.change_pass_required) {
    currentRoutes[0].children = routes.concat(ChangePassRequiredRoutes)
    return currentRoutes
  }

  // // SUBSCRIPTION EXPIRED
  // if (agency?.paid_account && agency?.stripe?.status !== 'active') {
  //   return routes.concat(SubscriptionExpiredRoutes)
  // }

  // ADMIN
  if (currentUser) {
    currentRoutes[0].children = routes.concat(AdminRoutes())
    return currentRoutes
  }

  return routes
}

export default Routes
