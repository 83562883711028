import React from 'react'

function DropdownIcon({style}) {
  return (
    <svg
      class='w-3 h-3'
      aria-hidden='true'
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 10 6'
      style={style}
    >
      <path
        stroke='currentColor'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='2'
        d='m1 1 4 4 4-4'
      />
    </svg>
  )
}

export default DropdownIcon
