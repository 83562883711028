import { AuthInit } from 'context/auth'
import { useEffect } from 'react'
import { useRoutes } from 'react-router-dom'
import Routes from 'routing/routes'
import { setGHLSessionKey } from 'utils/auth-helper'

function App() {
  const routes = useRoutes(Routes())

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const sessionkey = queryParams.get('sessionkey')
    if (sessionkey) setGHLSessionKey(sessionkey)
  }, [])

  return <AuthInit>{routes}</AuthInit>
}

export default App
