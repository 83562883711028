import React, { lazy } from 'react'
import ComponentLoader from './component-loader'
import AdminLayout from 'screens/admin/AdminLayout'
import { Navigate } from 'react-router-dom'
import PremiumAppsRoutes from './premuim-apps-routes'
import ShareLink from 'screens/public/ShareLink/sharelink'

const Home = ComponentLoader(lazy(() => import('screens/admin/Home/home')))
const Viewdashboard = ComponentLoader(
  lazy(() => import('screens/admin/Locations/view-dashboard'))
)

const Dashboard = ComponentLoader(
  lazy(() => import('screens/admin/Dashboard/dashboard'))
)
const Newsfeed = ComponentLoader(lazy(() => import('screens/admin/Newsfeed')))

const Location = ComponentLoader(
  lazy(() => import('screens/admin/Locations/locations'))
)

const BrandModule = ComponentLoader(lazy(() => import('screens/admin/Brand')))

const BusinessModule = ComponentLoader(
  lazy(() => import('screens/admin/Business'))
)

const ApiKey = ComponentLoader(
  lazy(() => import('screens/admin/ApiKey/apikey'))
)

const GrowthTool = ComponentLoader(
  lazy(() => import('screens/admin/GrowthTool/growthtool'))
)
const WalletConfig = ComponentLoader(
  lazy(() => import('screens/admin/WalletConfig/walletconfig'))
)
const WalletContact = ComponentLoader(
  lazy(() => import('screens/admin/WalletConfig/contact/contact'))
)
const Contact = ComponentLoader(
  lazy(() => import('screens/admin/CityPass/contact/contact'))
)
const LocationConfig = ComponentLoader(
  lazy(() => import('screens/admin/LocationConfig/locationconfig'))
)
const ApiDocs = ComponentLoader(
  lazy(() => import('screens/admin/ApiDocs/apidocs'))
)

const Preview = ComponentLoader(
  lazy(() => import('screens/admin/Docs side/preview'))
)
const AvailablePreview = ComponentLoader(
  lazy(() => import('screens/admin/Available-Docs/preview'))
)

const ScannerConfig = ComponentLoader(
  lazy(() => import('screens/admin/ScannerConfig/scannerconfig'))
)

const SnapshotModule = ComponentLoader(
  lazy(() => import('screens/admin/Snapshot'))
)
const WCSnapshotModule = ComponentLoader(
  lazy(() => import('screens/admin/WC_Snapshot'))
)
const WCImportedSnapshot = ComponentLoader(
  lazy(() => import('screens/admin/WC_Snapshot/snaptabs/importedsnapshot'))
)
const SassConfiguratorModule = ComponentLoader(
  lazy(() => import('screens/admin/SaaSConfigurator'))
)
const CRMDetails = ComponentLoader(
  lazy(() => import('screens/admin/CrmDetails/crmdetails'))
)
const SaasConfig = ComponentLoader(
  lazy(() => import('screens/admin/SaaSConfigurator/saasconfig-main'))
)
const CreatePlan = ComponentLoader(
  lazy(() => import('screens/admin/SaaSConfigurator/saasconfigurator'))
)

const Offers = ComponentLoader(lazy(() => import('screens/admin/Offers')))
//WEBHOOKS
const WebhookModule = ComponentLoader(
  lazy(() => import('screens/admin/Webhook/'))
)
const Webhook = ComponentLoader(
  lazy(() => import('screens/admin/Webhook/webhook-list'))
)
const WebhookLogs = ComponentLoader(
  lazy(() => import('screens/admin/Webhook/view-logs'))
)
const CreateWebhook = ComponentLoader(
  lazy(() => import('screens/admin/Webhook/create/create-webhook'))
)

const MarketPlace = ComponentLoader(
  lazy(() => import('screens/admin/MarketPlace/marketplace'))
)

// SUPPORT
const Support = ComponentLoader(lazy(() => import('screens/admin/Support/')))

const BasicInfo = ComponentLoader(
  lazy(() => import('screens/admin/BasicInfo/'))
)

const Billing = ComponentLoader(
  lazy(() => import('screens/admin/Bilings/billing'))
)

const ClientAccess = ComponentLoader(
  lazy(() => import('screens/admin/ClientAccess/clientaccess'))
)

const Integration = ComponentLoader(
  lazy(() => import('screens/admin/Integration/integration'))
)

const Links = ComponentLoader(lazy(() => import('screens/admin/Links/links')))

const Rebranding = ComponentLoader(
  lazy(() => import('screens/admin/Rebranding/rebranding'))
)

const Team = ComponentLoader(lazy(() => import('screens/admin/Teams')))
const LocationsUsers = ComponentLoader(
  lazy(() => import('screens/admin/LocationsUsers/locationsusers'))
)

// INTEGRATIONS
const Associate = ComponentLoader(
  lazy(() => import('screens/public/integration/ghl/associate'))
)
const IntegrateStripe = ComponentLoader(
  lazy(() => import('screens/public/integration/stripe/stripe'))
)

// PROFILE
const Profile = ComponentLoader(
  lazy(() => import('screens/admin/Profile/profile'))
)

const AdminRoutes = () => {
  const routes = [
    {
      path: '',
      element: <AdminLayout />,
      children: [
        {
          path: 'home',
          index: true,
          element: <Home />,
        },
        {
          path: 'dashboard',
          element: <Dashboard />,
        },
        {
          path: 'news',
          element: <Newsfeed />,
        },
        {
          path: 'business',
          element: <BusinessModule />,
        },
        {
          path: 'brand',
          element: <BrandModule />,
        },
        {
          path: 'loadsnapshot/:code',
          element: (
            <div className='p-12'>
              <ShareLink />
            </div>
          ),
        },

        {
          path: 'location',
          children: [
            {
              element: <Location />,
              path: '',
              index: true,
            },
            {
              path: 'view-dashboard',
              element: <Viewdashboard />,
            },
          ],
        },
        // {
        //   path: 'offers',
        //   element: <Offers />,
        // },
        {
          path: 'webhook',
          element: <WebhookModule />,
          children: [
            {
              path: '',
              index: true,
              element: <Webhook />,
            },
            {
              path: 'create-webhook',
              element: <CreateWebhook />,
            },
            {
              path: 'update',
              element: <CreateWebhook />,
            },
            {
              path: 'view-logs',
              element: <WebhookLogs />,
            },
          ],
        },

        {
          path: 'locationsusers',
          element: <LocationsUsers />,
        },

        {
          path: 'market',
          element: <MarketPlace />,
        },
        {
          path: 'support',
          element: <Support />,
        },
        ...PremiumAppsRoutes(),
        {
          path: 'profile',
          element: <Profile />,
        },
        {
          path: 'docs/preview',
          element: <Preview />,
        },
        {
          path: 'available-docs/preview',
          element: <AvailablePreview />,
        },
        {
          path: 'growthtool',
          element: <GrowthTool />,
        },
        // {
        //   path: 'apidocs',
        //   element: <ApiDocs />,
        // },
        {
          path: 'wallet-branding',
          element: <WalletConfig />,
        },
        {
          path: 'wallet-contact',
          element: <WalletContact />,
        },
        {
          path: 'citypass-contact',
          element: <Contact />,
        },
        {
          path: 'locationConfig',
          element: <LocationConfig />,
        },
        {
          path: 'scannerConfig',
          element: <ScannerConfig />,
        },
        {
          path: 'wc-snapshot',
          element: <WCSnapshotModule />,
        },
        {
          path: 'wc-snapshot/:tab',
          element: <WCSnapshotModule />,
        },
        {
          path: 'sassconfigurator',
          element: <SassConfiguratorModule />,
          children: [
            {
              paht: '',
              index: true,
              element: <SaasConfig />,
            },
            {
              path: 'create',
              element: <CreatePlan />,
            },
          ],
        },
        {
          path: 'crmdetails',
          element: <CRMDetails />,
        },

        {
          path: 'settings',
          children: [
            {
              path: 'client',
              element: <ClientAccess />,
            },
            {
              path: 'info',
              element: <BasicInfo />,
            },
            {
              path: 'team',
              element: <Team />,
            },
            {
              path: 'links',
              element: <Links />,
            },
            {
              path: 'rebranding',
              element: <Rebranding />,
            },
            {
              path: 'integration',
              element: <Integration />,
            },
            {
              path: 'billing',
              element: <Billing />,
            },
            {
              path: 'apikey',
              element: <ApiKey />,
            },
            {
              path: 'snapshot',
              element: <SnapshotModule />,
            },
          ],
        },
        {
          path: '',
          index: true,
          element: <Navigate replace to={'/home'} />,
        },
        {
          path: '*',
          index: true,
          element: <Navigate replace to={'/home'} />,
        },
      ],
    },
    {
      path: 'integrations',
      children: [
        {
          path: 'ghl',
          children: [
            {
              path: 'associate',
              element: <Associate />,
            },
          ],
        },
        {
          path: 'crm',
          children: [
            {
              path: 'associate',
              element: <Associate />,
            },
          ],
        },
        {
          path: 'stripe',
          element: <IntegrateStripe />,
        },
      ],
    },
    {
      path: '',
      element: <Navigate replace to={'/'} />,
    },
    {
      path: '*',
      element: <Navigate replace to={'/'} />,
    },
  ]

  return routes
}

export default AdminRoutes
