import React, { useState, createContext, useContext, useEffect } from 'react'
import Api from 'utils/api'

const defaultStyle = {
  sidenav_bg_color: '#FFFFFF',
  sidenav_text_color: '#323232',
  sidenav_active_text_color: '#000000',
  sidenav_active_bg_color: '#9ca3af',
  sidenav_button_text_color: '#FFFFFF',
  sidenav_button_bg_color: '#1d4ed8',
  button_text_color: '#FFFFFF',
  button_bg_color: '#1d4ed8',
  card_bg_color: '#9ca3af',
  card_text_color: '#FFFFFF',
  page_bg_color: '#FFFFFF',
  page_text_color: '#000000',
}

const initAgencyContextPropsState = {
  agency: undefined,
  setAgency: () => {},
  premiumApps: [],
  setPremiumApps: () => {},
  locations: [],
  setLocations: () => {},
  getAgency: async () => {},
  getPremiumApps: async () => {},
  getLocations: async () => {},
  styles: defaultStyle,
}

const AgencyContext = createContext(initAgencyContextPropsState)

const useAgency = () => {
  return useContext(AgencyContext)
}

const AgencyProvider = ({ children }) => {
  const [agency, setAgency] = useState()
  const [premiumApps, setPremiumApps] = useState([])
  const [locations, setLocations] = useState([])

  const getAgency = async () => {
    const { response } = await Api('/agency', 'get')
    if (response) setAgency(response.data)
    else setAgency()
  }

  const getPremiumApps = async () => {
    const { response } = await Api('/agency/premium_apps', 'get')
    if (response) setPremiumApps(response.data)
  }

  const getLocations = async () => {
    const { response } = await Api('/agency/locations', 'get')
    if (response) setLocations(response.data)
  }

  const styles = agency?.agency_config ? agency.agency_config : defaultStyle


  const onload = () => {
    document.body.setAttribute('style', `background-color: ${agency?.agency_config?.page_bg_color}`)
  }

  useEffect(onload, [agency?.agency_config])

  return (
    <AgencyContext.Provider
      value={{
        agency,
        setAgency,
        premiumApps,
        setPremiumApps,
        getAgency,
        getPremiumApps,
        locations,
        setLocations,
        getLocations,
        styles,
      }}
    >
      {children}
    </AgencyContext.Provider>
  )
}

export { AgencyProvider, useAgency }
