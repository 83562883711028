import React, { useState, createContext, useContext, useEffect } from 'react'
import Api from 'utils/api'
import { useAgency } from './agency'

const initLocationContextPropsState = {
  location: undefined,
  setLocation: () => {},
}

const LocationContext = createContext(initLocationContextPropsState)

const useLocationInfo = () => {
  return useContext(LocationContext)
}

const LocationProvider = ({ children }) => {
  const { agency } = useAgency()
  const [location, setLocation] = useState()

  // const getLocation = async () => {
  //   const { response } = await Api(
  //     '/agency/selected_location?selected_location_id=' +
  //       agency.selected_location,
  //     'get'
  //   )
  //   if (response) setLocation(response.data)
  // }

  // const onLoad = () => {
  //   getLocation()
  // }

  // useEffect(onLoad, [])

  return (
    <LocationContext.Provider
      value={{
        location,
        setLocation,
      }}
    >
      {children}
    </LocationContext.Provider>
  )
}

export { LocationProvider, useLocationInfo }
